/**
 * Copyright (C) 2024 Checkmk GmbH - License: GNU General Public License v2
 * This file is part of Checkmk (https://checkmk.com). It is subject to the terms and
 * conditions defined in the file COPYING, which is part of this source code package.
 */
export const inputSizes = {
  SMALL: { width: 7, height: 12 },
  MEDIUM: { width: 20, height: 18 },
  LARGE: { width: 60, height: 35 }
}
